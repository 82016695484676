import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['General Construction', 'Data Networks'];

export const _homePlans = [...Array(2)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates'],
  options: ['JavaScript version', 'TypeScript version', 'Design Resources', 'Commercial applications'],
  icons: [
  ],
  services: [
    ['Horizontal and Vertical General Civil Works', 'Roadway, Drainages, Manholes, Fire Protection, Plumbing and Sanitary', 'Electrical Works, Architectural Works, Pavement, Airport Horizontal Structures and Bridges', 'Conduit line construction and direct buried cable laying', 'Pole erection', 'Bridge Attachments', 'Cable and Equipments recovery', 'Prefabrication of manholes, handholes and service box', 'Communication tower fabrication and installation of racks, frames and cable runways', 'Engineering and Construction of residential buildings', 'Engineering and Construction of commercial buildings'],
    ['Fiber Optic Network technical study and planning', 'Fiber Optic Network design and implementation (Full Turnkey)', 'Network interface joints and links', 'Backbone and distribution network construction', 'Equipment and IT peripherals installations', 'Copper Cable Outside plant works', 'Copper Cable Transmission works', 'Installation of cable entrance facility', 'Aerial and underground cable installation', 'Supply of network equipments and cables']
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'basic',
    icon: <PlanFreeIcon />,
    price: 0,
    caption: 'forever',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: false },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'current plan',
  },
  {
    subscription: 'starter',
    icon: <PlanStarterIcon />,
    price: 4.99,
    caption: 'saving $24 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'choose starter',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: 9.99,
    caption: 'saving $124 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: true },
      { text: 'Permissions & workflows', isAvailable: true },
    ],
    labelAction: 'choose premium',
  },
];
