import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'services-and-projects', element: <Services /> },
        { path: 'civil-works', element: <Civilworks /> },
        { path: 'residential', element: <Residential /> },
        { path: 'whats-new', element: <WhatsNew /> },
        { path: 'telecom', element: <Telecom /> },
        { path: 'gallery', element: <Gallery /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}



// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Services = Loadable(lazy(() => import('../pages/Services')));
const Civilworks = Loadable(lazy(() => import('../pages/Civilworks')));
const Residential = Loadable(lazy(() => import('../pages/Residential')));
const Telecom = Loadable(lazy(() => import('../pages/Telecom')));
const Gallery = Loadable(lazy(() => import('../pages/Gallery')));
const WhatsNew = Loadable(lazy(() => import('../pages/WhatsNew')));
